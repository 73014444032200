.block-gallery {
	padding: 3rem 0;

	@include media-breakpoint-down(md) {
		padding: 2rem 0;
	}

	@include media-breakpoint-up(xxl) {
		padding: 4rem 0;
	}

	.gallery-slider {
		.swiper-slide {
			height: auto !important;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 3rem;

			picture,
			img {
				object-fit: contain;
			}
		}
	}

	&.with-border {
		.gallery-slider {
			// box-sizing: border-box;
			// border: 1px dashed rgba($text, 0.25);
			// border-radius: 1rem;
			// background: $white;
			// box-shadow: 0 0.375rem 0.375rem rgba($black, 0.15);
			// box-shadow: 0 1.375rem 1.625rem #00000012;

			.swiper-wrapper {
				.swiper-slide {
					padding: 1rem;
					box-shadow: 0 0.375rem 0.375rem rgba($black, 0.15);
					box-shadow: 0 1.375rem 1.625rem #00000012;
					border-radius: 0.375rem;
				}
			}
		}
	}

	&.grayscale {
		.gallery-slider {
			.swiper-slide {
				picture,
				img {
					filter: grayscale(1);
					transform: 0.2s linear;
					&:hover {
						filter: grayscale(0);
					}
				}
			}
		}
	}
}
