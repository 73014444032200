.block-heading {
	margin-bottom: 6rem;
	position: relative;
	background: $body;
	overflow-x: clip;

	@include media-breakpoint-down(md) {
		margin-bottom: 4rem;
	}

	.clip {
		position: absolute;
		bottom: -1px;
		left: 0;
		height: 3vw;
		width: 100%;

		path {
			fill: $body;
		}
	}

	.wrapper {
		position: relative;
		display: grid;
		grid-template-columns: 50% auto;
		grid-template-rows: auto 1fr;
		padding: 3rem 0;
		gap: 0 2rem;
		color: $white;
		min-height: 30rem;

		@include media-breakpoint-down(md) {
			padding: 2rem 0;
		}

		@include media-breakpoint-down(lg) {
			grid-template-columns: 1fr;
			min-height: auto;
		}

		.content {
			.label {
				@extend .typo-l;
				margin-bottom: 0.25rem;
			}

			.title {
				@extend .typo-h1;
				margin: 0 0 1.25rem;
				color: inherit;
			}

			.text {
				@extend .typo-r;
			}

			.link {
				display: flex;
				flex-wrap: wrap;
				margin-top: 1.5rem;
				gap: 1rem;
			}
		}

		.media {
			position: relative;
			flex-shrink: 0;
			flex-grow: 0;
			display: flex;
			align-items: start;
			z-index: 1;

			@include media-breakpoint-down(lg) {
				display: none;
			}

			.aesse-image {
				margin-right: 0;
				height: calc(30rem);
				position: absolute;
				right: 0;
				top: 0;

				@include media-breakpoint-down(md) {
					margin: 0 auto 2rem;
					max-width: 80%;
					height: auto;
					position: relative;
				}
				.frame {
					// overflow: clip;
					border-radius: 50%;
					position: relative;

					&::before {
						z-index: 2;
					}

					picture,
					img {
						transform: scale(0.99);
					}
				}

				&::after {
					right: -10%;
					bottom: auto;
					left: auto;
				}
			}
		}
	}

	&.color-azure {
		background: linear-gradient(
			0deg,
			rgba($azure, 1) 0%,
			rgba($azure, 1) 0%,
			rgba($azure, 0.5) 100%
		);
	}

	&.color-azure {
		background: linear-gradient(
			0deg,
			rgba($azure, 1) 0%,
			rgba($azure, .9) 50%,
			rgba($azure, .75) 100%
		);
	}

	&.color-orange {
		background: linear-gradient(
			0deg,
			rgba($orange, 1) 0%,
			rgba($orange, .9) 50%,
			rgba($orange, .75) 100%
		);
	}

	&.color-green {
		background: linear-gradient(
			0deg,
			rgba($green, 1) 0%,
			rgba($green, .9) 50%,
			rgba($green, .75) 100%
		);
	}

	&.color-sky {
		background: linear-gradient(
			0deg,
			rgba($sky, 1) 0%,
			rgba($sky, .9) 50%,
			rgba($sky, .75) 100%
		);
	}

	&.color-violet {
		background: linear-gradient(
			0deg,
			rgba($violet, 1) 0%,
			rgba($violet, .9) 50%,
			rgba($violet, .75) 100%
		);
	}
}
