.btn {
	display: inline-flex;
	font-size: 0.75rem;
	letter-spacing: 0.25em;
	line-height: 1;
	align-items: center;
	justify-content: center;
	transition: 0.3s linear;
	padding: 0.9375rem 1.5rem;
	text-decoration: none;
	border-radius: 2rem;
	background: $azure;
	color: $white;
	text-align: center;
	border: 0;
    text-transform: uppercase;
	cursor: pointer;
	font-weight: 600;

	@include media-breakpoint-down(sm) {
		width: 100%;
		max-width: 80vw;
		flex-grow: 1;
	}

	&.white {
		background: $white;
		color: $text;
	}

	&.dark {
		background: $text;
		color: $white;

		&:hover {
			background: $azure;
			color: $white;
		}
	}

	&.transparent {
        background: none;
		border: 1px solid $white;

		&:hover {
			border-color: $text;
		}
	}

	&.dark-transparent {
        background: none;
		color: $text;
		border: 1px solid $text;

		&:hover {
			border-color: $text;
		}
	}

	&.white-transparent {
        background: none;
		color: $white;
		border: 1px solid $white;

		&:hover {
			border-color: $text;
		}
	}

	&:hover {
		background: $text;
		color: $white;
	}

	i {
		margin-left: 1rem;
		transform: scale(1.125);
	}

}
