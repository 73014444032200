.block-files {
	padding: 3rem 0;

	@include media-breakpoint-down(md) {
		padding: 2rem 0;
	}

	@include media-breakpoint-up(xxl) {
		padding: 4rem 0;
	}

	.grid {
		position: relative;
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 1.5rem;

		@include media-breakpoint-down(md) {
			grid-template-columns: 1fr;
		}

		.item {
			display: grid;
			grid-template-columns: 40% auto;
			gap: 1.5rem;
			background: $white;
			box-shadow: 0 0.375rem 0.375rem rgba($black, 0.15);
			box-shadow: 0 1.375rem 1.625rem #00000012;
			border-radius: 0.375rem;
			padding: 1rem;
			align-items: start;
			text-decoration: none;

			@include media-breakpoint-up(xxl) {
				align-items: center;
			}

			.media {
				border-radius: 0.375rem;
				aspect-ratio: 1 / 1;
				overflow: clip;
				border: 1px solid rgba($text, 0.15);
				display: flex;
				align-items: center;
				justify-content: center;

				a,
				[data-bs-toggle] {
					cursor: pointer;
				}

				i {
					font-size: 4rem;
					color: $azure;
				}

				picture,
				img {
					object-fit: cover;
					object-position: top center;
					width: 100%;
					height: 100%;
				}
			}

			.content {
				.title {
					font-weight: bold;
					font-size: 1.25rem;
				}
				.label {
					@extend .typo-l;
					color: $azure;
					margin-bottom: .25rem;
				}
				.text {
					margin-top: .75rem;
				}
				.link {
					margin-top: 1.5rem;
				}
			}

			// &:hover {
			// 	background: $azure;
			// 	color: $white;

			// 	.content {
			// 		.label {
			// 			color: $white;
			// 		}
			// 	}

			// 	.media {
			// 		border-color: $white;
			// 	}
			// }
		}
	}
}
