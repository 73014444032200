.breadcrumbs {
    
    .breadcrumb {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        text-transform: uppercase;
        list-style: none;
        z-index: 1;
        position: relative;
        color: $black;

        li {
            display: inline-block;
            padding: 0;

            &::before {
                display: none;
            }
            &::after {
                font-family: $font-icons;
                content: "\f105";
                transform-origin: center;
                font-size: .75rem;
                padding: 0;
                margin: 0 .375rem;
            }

            &:last-child {

                &::after {
                    display: none;
                }
            }

            a {
                @extend .typo-l;
                transition: .3s linear;
                text-decoration: none;
                letter-spacing: .25em;
                // font-weight: bold;

                &:hover {
                    color: $azure;
                }
            }
        }
    }

    .single-post & {
        .breadcrumb {
            li {
                &:last-child {
                    // display: none;
                }
            }
        }
    }

}