// .no-js img.lazyload {
//     display: none;
// }

// .lazyload,
// .lazyloading {
//     opacity: 0;

// }

// .lazyloaded {
//     opacity: 1;
//     transition: opacity 500ms;
// }

// .blur-up {
//     -webkit-filter: blur(5px);
//     filter: blur(5px);
//     transition: filter 400ms, -webkit-filter 400ms;
// }

// .blur-up.lazyloaded {
//     -webkit-filter: blur(0);
//     filter: blur(0);
// }

img[loading="lazy"] {
    opacity: 0;
    transition: 0.3s linear;
}

img[loading="lazy"]:not([src=""]) {
    opacity: 1;
}