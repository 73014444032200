.form {
	// background: $white;
	// padding: 3rem;
	// height: 100%;
	align-self: center;

	@include media-breakpoint-down(md) {
		padding: 2rem;
	}
}

.hubspot-form {
	margin-top: 2rem;

	form {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 1rem;

		// Hubspot overrides
		.hs-fieldtype-text,
		.hs-fieldtype-textarea,
		.hs-fieldtype-phonenumber,
		.hs-fieldtype-select {
			& > label {
				// display: none;
				font-size: 0.875rem;
				margin-bottom: 0.25rem;
			}
		}

		.hs-fieldtype-text,
		.hs-fieldtype-phonenumber {
			.input {
				input {
					@extend .form-control;
					border-color: rgba($text, 0.25);
					border-radius: 0.25rem;
					padding: 0.5rem;
				}
			}
		}

		.hs-fieldtype-textarea {
			&.hs-message {
				grid-column: span 2;
			}

			.input {
				textarea {
					@extend .form-control;
					height: auto;
					border-color: rgba($text, 0.25);
					border-radius: 0.25rem;
				}
			}
		}

		.hs-fieldtype-select {
			.input {
				select {
					@extend .form-select;
					border-color: rgba($dark, 0.25);
					border-radius: 0.25rem;
				}
			}
		}

		.hs-fieldtype-booleancheckbox,
    .legal-consent-container {
			grid-column: span 2;

			.inputs-list {
				padding: 0;
				margin: 0;
				list-style: none;

				.hs-form-booleancheckbox-display {
					@extend .form-check;

					input {
						@extend .form-check-input;
            border-radius: 0 !important;
            height: .875rem;
            width: .875rem;
            margin-top: 1px;

            &:checked {
              background-color: $azure;
              border-color: $azure;
            }
					}

					span {
						font-size: 0.75rem;
            margin-left: 0;
						@extend .form-check-label;
					}
				}
			}
		}

		.hs-fieldtype-radio {
			grid-column: span 2;

			.inputs-list {
				padding: 0;
				margin: 0;
				list-style: none;
				display: flex;
				gap: 1rem;
				margin-top: 0.5rem;

				.hs-form-radio-display {
					@extend .form-check;

					input {
						@extend .form-check-input;
					}

					span {
						font-size: 0.875rem;
						@extend .form-check-label;
					}
				}
			}
		}

		.hs-error-msgs {
			list-style: none;
			font-size: 0.75rem;
			margin: 0.25rem 0 0;
			padding: 0;
			color: red;
		}

    .hs_recaptcha {
      display: none;
    }

		a {
			color: $azure;
			font-weight: bold;
		}

		.hs-submit {
			display: flex;
			justify-content: start;
			align-items: center;
			grid-column: 1 / 3;

			input {
				@extend .btn;
				border: 0;
			}
		}
	}
}