.post-header {
	background: $white;
	.advanced-header {
		.top {
			.background {
				background: $azure;
				opacity: 1;
				width: 100%;
				position: absolute;
				z-index: 0;

				&::after {
					content: "";
					position: absolute;
					height: 100%;
					width: 100%;
					background: var(--cover) no-repeat center / cover;
					top: 0;
					left: 0;
					mix-blend-mode: multiply;
					filter: grayscale(1);
					z-index: 0;
					opacity: 0.25;
				}

				.clip {
					position: absolute;
					bottom: -1px;
					left: 0;
					height: 3vw;
					width: 100%;
					z-index: 1;

					path {
						fill: $white;
					}
				}
			}

			.wrapper {
				position: relative;
				display: flex;
				justify-content: center;

				.content {
					text-align: center;

					@include media-breakpoint-down(xl) {
						padding-top: 3rem;
					}

					.breadcrumbs {
						margin-bottom: 3rem;

						@include media-breakpoint-down(md) {
							margin-bottom: 2rem;
						}
						.breadcrumb {
							justify-content: center;
							color: $white;
						}
					}

					.title {
						@extend .typo-h1;
						margin: 0;
						color: $white;
					}

					.date {
						@extend .typo-l;
						margin: 1rem 0 0;
						color: $white;
					}
				}
			}
		}
	}
}

.post-share {
	display: flex;
	align-items: center;
	margin: 1rem 0;
	display: none;

	@include media-breakpoint-down(md) {
		justify-content: flex-start;
	}

	a {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 1rem;
		text-decoration: none;
		font-size: 1.5rem;
		cursor: pointer;

		&:hover {
			color: $azure;
		}
	}
}

.post-content {
	margin: 0 auto;
}

.related-posts {
	background: $white;
	@extend .blog-grid;

	.intro {
		text-align: center;

		.title {
			@extend .typo-h3;
		}
	}

	.post-grid {

		@include media-breakpoint-down(xxl) {
			:nth-child(4) {
				display: none;
			}
		}

		@include media-breakpoint-down(lg) {
			:nth-child(3) {
				display: none;
			}
		}
	}
}
