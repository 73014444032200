.block-timeline {
	padding: 3rem 0;

	@include media-breakpoint-down(md) {
		padding: 2rem 0;
	}

	@include media-breakpoint-up(xxl) {
		padding: 4rem 0;
	}

	.time-slide {
		display: flex;
		flex-direction: column;

        .year {
            color: $azure;
            padding: 0 2rem 1rem 0;
            display: block;
            border-bottom: 2px solid $azure;
            font-weight: bold;
            font-size: 1.25rem;
            margin-bottom: 1rem;
            position: relative;

			&:before {
				position: absolute;
				flex: 0 0 auto;
				bottom: calc(-.5rem - 1px);
				height: 1rem;
				width: 1rem;
				margin-top: .0625rem;
				margin-right: 0.5rem;
				display: flex;
				border-radius: 50%;
				content: "";
				background: $white;
				border: .375rem solid $azure;
			}
        }

        .text {
            @extend .typo-r;
            padding-right: 2rem;
        }
	}

	.timeline-slider {
		.swiper-slide {
			height: auto !important;
			display: flex;
		}
        .timeline-pagination {
            margin-top: 2rem;
        }
	}
}
