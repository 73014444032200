.block-image {
	padding: 3rem 0;

	@include media-breakpoint-down(md) {
		padding: 2rem 0;
	}

	@include media-breakpoint-up(xxl) {
		padding: 4rem 0;
	}

	.image {
		picture,
		img {
			width: 100%;
			object-fit: contain;
			box-shadow: 0;
		}
	}
}
