.footer {
	position: relative;
	background: $white;
	color: $text;
	overflow: clip;
	border-top: 1px solid $menu;

	.wrapper {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		margin: 0 -1rem;

		@include media-breakpoint-down(md) {
			grid-template-columns: 1fr;
		}

		.footer-left {
			display: grid;
			background: $white;
			padding: 5rem 5rem 5rem 1rem;
			grid-template-columns: repeat(2, 1fr);
			width: 100%;
			align-self: start;
			gap: 2rem;

			@include media-breakpoint-down(xl) {
				padding-right: 4rem;
			}

			@include media-breakpoint-down(lg) {
				padding-right: 1rem;
			}

			.footer-logo {
				grid-column: span 2;

				img {
					height: 2.5rem;
					width: auto;
					max-width: 100%;
					// max-width: 9.25rem;
	
					@include media-breakpoint-up(xxl) {
						height: 3.5rem;
						// max-width: auto;
					}
	
					@include media-breakpoint-down(xl) {
						height: 2rem;
						// max-width: auto;
					}
	
					@include media-breakpoint-down(lg) {
						height: 2.5rem;
					}
				}
			}

			.pa-logo {
				margin-bottom: 2rem;
				
				img {
					width: auto;
					max-width: 100%;
				}
			}

			.menu {
				margin-bottom: 2rem;

				a {
					display: flex;
					font-size: 0.9375rem;
					margin-bottom: 0.875rem;
					text-decoration: none;
				}
			}

			.contacts {
				margin-bottom: 2rem;

				.contact {
					display: flex;
					white-space: normal;
					font-size: 0.875rem;
					line-height: 1;
					color: $black;
					font-weight: normal;
					text-decoration: none;
					gap: 0.75rem;
					flex-grow: 1;
					margin-bottom: 1.5rem;
					align-items: center;

					i {
						font-size: 1.25rem;
						color: $azure;
					}

					.detail {
						white-space: wrap;
						line-height: 1.25;

						strong {
							display: block;
							font-weight: bold;
						}
					}
				}

				.social-links {
					display: flex;
					margin: 2.5rem 0 0;
					align-items: center;
					gap: 1rem;

					a {
						display: flex;
						color: $azure;
						text-decoration: none;
						font-size: 2rem;
						line-height: 1;
						align-items: center;
						justify-content: center;

						&:hover {
							color: $text;
						}
					}
				}
			}

			.infos {
				// margin-top: auto;
				grid-column: span 2;

				.logos {
					display: grid;
					margin-bottom: 2rem;
					grid-template-columns: repeat(2, 1fr);
					align-items: center;
					gap: 1rem;

					@include media-breakpoint-down(lg) {
						justify-content: start;
						grid-template-columns: 1fr;

						.footer-logo {
							display: none;
							
							img {
								max-height: 3rem;
							}
						}
					}
				}

				.credits,
				.company {
					font-size: 0.875rem;
				}
			}
		}

		.footer-right {
			position: relative;
			background: $menu;
			padding: 5rem 1rem 5rem 5rem;

			@include media-breakpoint-down(xl) {
				padding-left: 4rem;
			}

			@include media-breakpoint-down(lg) {
				padding-left: 1rem;
			}

			&:after {
				position: absolute;
				top: 0;
				right: -100vw;
				content: "";
				background: $menu;
				width: 100vw;
				height: 100%;

				@include media-breakpoint-down(md) {
					padding: 5rem 0;
				}
			}

			.newsletter {
				@include media-breakpoint-up(xxl) {
					max-width: 70%;
				}

				.hubspot-form {
					.hs_email {
						grid-column: span 2;
					}
				}
			}
		}
	}

	.title {
		font-weight: bold;
		font-size: 1.5rem;
		letter-spacing: 0.015em;
		margin-bottom: 1rem;
		line-height: 1;
	}
}
