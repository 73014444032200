.block-case {
	padding: 3rem 0;
	text-align: center;
	position: relative;

	@include media-breakpoint-down(md) {
		padding: 2rem 0;
	}

	@include media-breakpoint-up(xxl) {
		padding: 4rem 0;
	}

	.wrapper {
		background-color: $azure;
		background: url("../images/waves.svg") no-repeat $azure top center /
			cover;
		padding: 2rem 0;
		border-radius: 0.375rem;

		@include media-breakpoint-down(md) {
			padding: 1rem 0;
		}

		.case {
			color: $white;
			padding: 0 5rem;
			display: block;
			text-align: center;
			position: relative;

			@include media-breakpoint-down(md) {
				padding: 0 3rem;
			}

			.logo {
				margin-bottom: 1rem;
				display: flex;
				justify-content: center;

				picture,
				img {
					max-width: 15rem;
				}
			}

			.text {
				@extend .typo-h3;
				line-height: 1.25;
			}

			.cite {
				font-size: 1rem;
				margin-top: 1rem;
			}

			.link {
				display: flex;
				gap: 1rem;
				justify-content: center;
				margin-top: 1.5rem;
				flex-wrap: wrap;
			}
		}
	}
}
