// Font Awesome
$fa-font-path: "../fonts/fontawesome6" !default;

// Options
$enable-caret: false !default;
$enable-rounded: true !default;
$enable-shadows: false !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-reduced-motion: true !default;
$enable-smooth-scroll: true !default;
$enable-grid-classes: true !default;
$enable-container-classes: true !default;
$enable-cssgrid: false !default;
$enable-button-pointers: true !default;
$enable-rfs: false !default;
$enable-validation-icons: false !default;
$enable-negative-margins: false !default;
$enable-deprecation-messages: false !default;
$enable-important-utilities: true !default;
$enable-dark-mode: false !default;
$color-mode-type: data !default; // `data` or `media-query`

// Grid breakpoints
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1600px,
) !default;

// Grid containers
$container-max-widths: (
	sm: 100%,
	md: 100%,
	lg: 100%,
	xl: 1140px,
	xxl: 1540px,
) !default;

// Grid options
// $grid-gutter-width: 2rem !default;

// Typography
$headings-font-weight: bold !default;
$display-font-weight: normal !default;
$lead-font-weight: normal !default;

// Components
$border-radius: 0 !default;

// Buttons + Forms
$input-btn-focus-box-shadow: 0 !default;
$btn-focus-box-shadow: 0 !default;

// Modals and offcanvas
$modal-backdrop-opacity:            .85 !default;