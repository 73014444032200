.case-grid {
	.case-tease {
		display: flex;
		position: relative;
		background: $white;
		box-shadow: 0 0.375rem 0.375rem rgba($black, 0.15);
		box-shadow: 0 1.375rem 1.625rem #00000012;
		text-align: center;
		flex-direction: column;
		align-items: center;
		border-radius: 0.375rem;
		padding: 1.5rem 2rem;
		// flex-grow: 1;
		margin-bottom: 2rem;

		.content {
			display: grid;
			position: relative;
			grid-template-areas: "text";
			z-index: 1;
			margin-bottom: auto;

			.front {
				grid-area: text;
				transition: 0.3s linear;
				z-index: 1;

				.title {
					margin-bottom: 1rem;
					@extend .typo-h3;
				}

				.text {
					@extend .typo-r;
				}
			}
			.back {
				opacity: 0;
				color: $white;
				grid-area: text;
				transition: 0.3s linear;
				z-index: 0;

				@extend .typo-r;

				ul,
				ol {
					margin: 0;
					li {
						border-bottom: 1px solid #fff;
						margin-bottom: 0.5rem;
						padding-bottom: 0.5rem;

						&::before {
							display: none;
						}
					}
				}
			}
		}

		.link {
			margin-top: 1rem;
			z-index: 3;
			display: flex;
			gap: 1rem;
			flex-wrap: wrap;
			width: 100%;
			justify-content: center;
		}

		&.with-icon {
			margin-top: 5rem;

			.icon {
				display: flex;
				position: relative;
				width: 7.5rem;
				height: 7.5rem;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				box-shadow: 0 0 0.75rem rgba($black, 0.15);
				margin: -5.5rem auto 2rem;
				background: $white;

				img {
					width: 5rem;
					height: 5rem;
					object-fit: contain;
					object-position: center;
				}
			}
		}
	}
}

.case-content {
	overflow-x: clip;
	padding: 3rem 0;
	background: $white;

	@include media-breakpoint-down(md) {
		padding: 2rem 0;
	}

	@include media-breakpoint-up(xxl) {
		padding: 4rem 0;
	}

	.case-profile,
	.case-challenge,
	.case-collaboration {
		@extend .typo-r;
		padding: 3rem 0;

		@include media-breakpoint-down(md) {
			padding: 2rem 0;
		}

		@include media-breakpoint-up(xxl) {
			padding: 4rem 0;
		}
	}

	.case-profile {
		position: relative;
		z-index: 1;

		&::before {
			content: "";
			width: 200vw;
			background: linear-gradient(to bottom, $white, $body);
			height: 100%;
			position: absolute;
			display: block;
			z-index: -1;
			left: -100vw;
			top: 0;
			bottom: -3rem;

			@include media-breakpoint-down(md) {
				bottom: -2rem;
			}

			@include media-breakpoint-up(xxl) {
				bottom: -4rem;
			}
		}
	}

	.case-table {
		position: relative;
		z-index: 2;
		display: flex;
		background: $white;
		box-shadow: 0 0.375rem 0.375rem rgba($black, 0.15);
		box-shadow: 0 1.375rem 1.625rem #00000012;
		flex-direction: column;
		gap: 1rem;
		border-radius: 0.375rem;
		padding: 1.5rem 2rem;
		margin: 3rem 0;

		@include media-breakpoint-down(md) {
			margin: 2rem 0;
		}

		@include media-breakpoint-up(xxl) {
			margin: 4rem 0;
		}

		.case-detail {
			margin-bottom: 1rem;
			.label {
				@extend .typo-l;
				color: $azure;
				margin-bottom: 0.25rem;
				font-weight: bold;
			}
			.value {
				@extend .typo-r;
				line-height: 1.2;
			}
		}

		.case-services,
		.case-results {
			.label {
				margin-bottom: 0.5rem;
			}
		}

		@include media-breakpoint-down(lg) {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 1rem;

			.case-logo,
			.case-client {
				grid-column: span 2;
			}
			.case-client {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				gap: 1rem;
			}
		}

		@include media-breakpoint-down(sm) {
			grid-template-columns: 1fr;
			gap: 1rem 0;
			.case-client {
				grid-template-columns: 1fr;
			}
			.case-services,
			.case-results {
				grid-column: span 2;
			}
		}
	}
}

.block-heading {
	&.single-case-heading {
		margin-bottom: 0;

		.clip {
			path {
				fill: $white;
			}
		}

		.wrapper {
			grid-template-rows: 1fr;
			align-content: center;

			.content {
				align-self: center;

				.breadcrumb {
					color: $white;

					a:hover {
						color: $text;
					}
				}
			}

			.media .aesse-image::after {
				right: auto;
				bottom: 0;
				left: -10%;
			}
		}
	}
}

.block-review {
	&.case-review {
		.review {
			background-color: $azure;
			background: url("../images/waves.svg") no-repeat $azure top center /
				cover;
			max-width: 69.75rem;
			border-radius: 0.375rem;

			.wrapper {
				padding: 3rem;
				display: flex;
				grid-template-columns: 1fr;
				gap: 1.5rem;

				@include media-breakpoint-down(md) {
					flex-direction: column;
					padding: 2rem;
					gap: 1rem;
				}

				&.with-media {
					grid-template-columns: 80% 20%;
				}

				.content {
					color: $white;
					display: block;
					position: relative;

					.text {
						@extend .typo-h3;
						line-height: 1.25;
					}

					.name {
						font-size: 1rem;
						margin-top: 1.5rem;
						font-weight: bold;
					}

					.role {
						font-size: 1rem;
					}
				}

				.media {
					display: flex;
					gap: 1rem;
					flex-direction: column;
					max-width: 25%;
					flex-shrink: 0;

					@include media-breakpoint-down(md) {
						flex-direction: row;
						max-width: 100%;
						align-items: center;
						gap: 1.5rem;
					}

					.image {
						border-radius: 50%;
						overflow: clip;

						@include media-breakpoint-down(md) {
							max-width: 40%;
						}
					}
				}
			}
		}
	}
}
