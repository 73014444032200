.block-text-image {
	padding: 3rem 0;
	overflow-x: clip;

	@include media-breakpoint-down(md) {
		padding: 2rem 0;
	}

	@include media-breakpoint-up(xxl) {
		padding: 4rem 0;
	}

	.wrapper {
		display: grid;
		gap: 1.5rem;
		align-items: center;

		.content {
			.label {
				@extend .typo-l;
				color: $azure;
				margin-bottom: 0.25rem;
			}

			.title {
				@extend .typo-h2;
				margin: 0 0 1.25rem;
			}

			.subtitle {
				@extend .typo-h3;
				margin: 0 0 1.25rem;
			}

			.text {
				@extend .typo-r;
				color: $text;
				margin: 0 auto;
			}

			.link {
				margin-top: 1.5rem;
				display: flex;
				gap: 1rem;
				flex-wrap: wrap;
			}
		}

		.media {
			z-index: 1;

			.aesse-image {
				width: 80%;

				.frame {
					&::before {
						z-index: 2;
						
					}

					picture,
					img {
						transform: scale(0.99);
					}
				}
			}
		}
	}

	&.with-media {
		.wrapper {
			grid-template-columns: repeat(2, 1fr);

			@include media-breakpoint-down(md) {
				grid-template-columns: 1fr;
			}
		}

		&.media-left {
			.media {
				order: 1;

				.aesse-image {
					margin-right: auto;
					margin-left: 0;

					@include media-breakpoint-down(md) {
						margin: 0 auto 2rem;
					}

					&::after {
						right: 0;
						top: -5%;
						bottom: auto;
						left: auto;
					}
				}
			}

			.content {
				order: 2;
			}
		}

		&.media-right {
			.content {
				order: 1;
			}

			.media {
				order: 2;

				.aesse-image {
					margin-left: auto;
					margin-right: 0;

					@include media-breakpoint-down(md) {
						margin: 0 auto 2rem;
					}

					&::after {
						bottom: -5%;
					}
				}
			}
		}

		@include media-breakpoint-down(lg) {
			&.media-right,
			&.media-left {
				.media {
					order: 2;
				}
			}

			.content {
				order: 1;
			}
		}
	}
}
