/* Dev */
body {
    &.dev {
        // background: url('../base/contatti.png') no-repeat center top / 1440px;
        height: 15000px;
        margin: 0 auto;
        position: relative;

        &.overlay:after {
            position: absolute;
            display: block;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            height: 100%;
            background: rgba($azure, .25);
            content: '';
            width: 1440px;
        }
    }

    .bg-dev {
        background: rgba($azure, .25) !important;
    }
}

pre {
    font-size: .875rem;
}