header.header {
	position: sticky;
	top: 0;
	background-color: $menu;
	z-index: 999;
	padding: 0;
	transition: 0.3s linear;
	box-shadow: 0 0 0.375rem rgba($black, 0.15);

	@include media-breakpoint-down(lg) {
		position: relative;
	}

	.navbar {
		display: flex;
		padding: 0;
		gap: 1.5rem;
		width: 100%;

		// LOGO
		.navbar-brand {
			display: flex;
			padding: 1rem 0;
			padding-right: 2rem;
			margin: 0;
			align-items: center;

			@include media-breakpoint-down(lg) {
				margin: 0 auto;
				padding-right: 5rem;
			}

			img {
				height: 2.5rem;
				width: auto;
				// max-width: 9.25rem;

				@include media-breakpoint-up(xxl) {
					height: 3.5rem;
					// max-width: auto;
				}

				@include media-breakpoint-down(xl) {
					height: 2rem;
					// max-width: auto;
				}

				@include media-breakpoint-down(lg) {
					height: 2.5rem;
				}
			}
		}

		// MENU

		// TOGGLERS
		.header-toggler {
			display: flex;
			border: 0;
			align-self: center;
			color: $text;
			border: 1px solid $text;
			background: none;
			width: 3rem;
			height: 2rem;
			padding: 0;
			transition: 0.3s;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			flex-shrink: 0;
			margin: 0;

			i {
				display: flex;
				font-size: 1rem;
				transform: scaleX(2);
				align-items: center;
				justify-content: center;
				transition: 0.3s;

				&:hover {
					color: inherit;
				}
			}
		}
	}
}

@media (hover: none) {
	.mobile-toggler {
		display: flex !important;
	}

	.navbar-brand {
		margin: 0 auto !important;;
		padding-right: 5rem !important;;

		img {
			height: 2.5rem !important;;
		}
	}
}
