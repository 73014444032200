// .white-space:has( + .white-space),
// .white-space:has( + .bg-towhite),
// .bg-towhite:has( + .bg-fromwhite),
// .bg-white:has( + .bg-white),
// .bg-fromwhite:has( + .white-space),
// .bg-white:has( + .bg-fromwhite),
// .page-header:has( + .bg-fromwhite),
// .page-header:has( + .bg-white),
// .boxed:has( + .boxed) {
// 	padding-bottom: 0;
// }

.white-space + .white-space,
.white-space + .bg-towhite,
.bg-towhite + .bg-fromwhite,
.bg-white + .bg-white,
.bg-fromwhite + .white-space,
.bg-white + .bg-fromwhite,
// .page-header + .bg-fromwhite,
// .page-header + .bg-white,
.boxed + .boxed {
	padding-top: 0;
}

[class^="block-"] {
	.intro {
		margin-bottom: 2rem;

		.label {
			@extend .typo-l;
			color: $azure;
			margin-bottom: 0.25rem;
		}

		.title {
			@extend .typo-h2;
			margin: 0 0 1rem;
		}

		.subtitle {
			@extend .typo-h3;
			margin: 0 0 1rem;
		}

		.text {
			@extend .typo-r;
			margin: 0 auto;
		}

		.link {
			margin-top: 1.5rem;
			display: flex;
			gap: 1rem;
			flex-wrap: wrap;
			width: 100%;
		}

		:last-child {
			margin-bottom: 0;
		}

		&.center {
			text-align: center;
			max-width: 80%;
			margin: 0 auto 2rem;

			.link {
				justify-content: center;
			}
		}
	}
}