.actionbar {
    display: grid;
    position: fixed;
    top: 30%;
    right: 0;
    // transform: translateY(-50%);
    gap: 1px;
    z-index: 99;

    @include media-breakpoint-down(sm) {
        grid-auto-flow: column;
        left: 0;
        bottom: 0;
        top: auto;
        transform: none;
        width: 100%;
        background: $white;
        border-top: 1px solid $white;
    }

    .trigger {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        color: $white;
        text-decoration: none;
        margin-left: auto;
        cursor: pointer;
        background: $text;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
            gap: .25rem;
            padding: .5rem;
            margin-left: 0;
        }

        .icon {
            display: flex;
            position: relative;
            height: 2.5rem;
            width: 2.5rem;
            font-size: 1.25rem;
            align-items: center;
            justify-content: center;
            z-index: 1;

            @include media-breakpoint-down(sm) {
                background: none;
            }
        }

        .full {
            display: inline-flex;
            background: $text;
            padding: 0 .5rem 0 1rem;
            background: $text;
            position: absolute;
            align-items: center;
            height: 2.5rem;
            top: 0;
            left: 0;
            white-space: nowrap;
            transition: 0.3s linear;
            opacity: 0;
            z-index: 0;
            font-size: .75rem;
            font-weight: bold;

            @include media-breakpoint-down(sm) {
                display: none;
                font-size: 0.625rem;
            }

        }

        .mini {
            display: none;
            text-align: center;
            font-size: .5rem;
            text-transform: uppercase;

            @include media-breakpoint-down(sm) {
                display: block;
            }
        }

        &:hover {

            .icon {
                background: $azure;

                @include media-breakpoint-down(sm) {
                    background: none;
                }
            }

            .full {
                transform: translateX(-100%);
                opacity: 1;
                background: $azure;
            }

            @include media-breakpoint-down(sm) {
                background: $azure;
            }
        }

    }

    .scroll-to-top {

        @include media-breakpoint-up(sm) {
            position: fixed;
            bottom: 0;
            right: 0;
        }
    }
}