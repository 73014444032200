.glossary {
	.glossary-index {
		position: sticky;
		top: 4.5rem;
        z-index: 1;

        @include media-breakpoint-down(xl) {
            top: 4rem;
        }

        @include media-breakpoint-up(xxl) {
            top: 5.5rem;
        }

		.triggers {
			display: grid;
			align-items: center;
			gap: 0.5rem;
            padding: 2rem 0;

			.trigger,
            .trigger:focus {
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 0.25rem;
				box-shadow: 0 0.75rem 1rem #00000012 !important;
				font-size: 1.25rem;
				font-weight: bold;
				aspect-ratio: 1 / 1;
				text-decoration: none;
				max-width: 2.5rem;
                background: $white;

                @include media-breakpoint-down(xxl) {
                    font-size: 1rem;
                }

				&:hover,
				&.active {
					color: $white;
					background: $azure;
                    box-shadow: 0 0.75rem 1rem #00000012;
				}
			}
		}
	}
    
    .glossary-content {
        position: relative;
        z-index: 0;
        padding: 3rem 0;

        .group {
            padding: 2rem 0;

            .index {
                font-size: 2rem;
                color: $azure;
                width: 4rem;
                border-bottom: .25rem solid $azure;
                padding: 0 0 .75rem;
                margin: 0;
            }
            .definitions {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                margin: 1rem 0;

                .definition {
                    padding-left: 4rem;
                    position: relative;

                    @include media-breakpoint-down(lg) {
                        padding-left: 0;
                    }

                    .title {
                        font-size: 1.25rem;
                        font-weight: bold;
                        margin: 0;
                    }
                    .description {
                        @extend .typo-r;
                    }

                    &:before {
                        content:"";
                        display: block;
                        height: 1.125rem;
                        width: 1.125rem;
                        border: 0.375rem solid $azure;
                        border-radius: 100%;
                        position: absolute;
                        left: .75rem;
                        top: .125rem;

                        @include media-breakpoint-down(lg) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
