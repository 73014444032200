.blog-grid {
	padding: 3rem 0;
	
	@include media-breakpoint-down(md) {
		padding: 2rem 0;
	}

	@include media-breakpoint-up(xxl) {
		padding: 4rem 0;
	}

	.intro {
		margin-bottom: 2rem;

		&.center {
			text-align: center;
			max-width: 80%;
			margin: 0 auto 2rem;
		}

		.label {
			@extend .typo-l;
			color: $azure;
			margin-bottom: 0.25rem;
		}

		.title {
			@extend .typo-h2;
			margin: 0 0 1rem;
		}

		.subtitle {
			@extend .typo-h3;
			margin: 0 0 1rem;
		}

		.text {
			@extend .typo-r;
			color: $text;
			margin: 0 auto;
		}

		.link {
			margin-top: 1.5rem;
			display: flex;
			gap: 1rem;
			flex-wrap: wrap;
		}

		:last-child {
			margin-bottom: 0;
		}
	}

	.post-grid {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: auto;
		gap: 1.5rem;

		@include media-breakpoint-down(xxl) {
			grid-template-columns: repeat(3, 1fr);
		}

		@include media-breakpoint-down(lg) {
			grid-template-columns: repeat(2, 1fr);
		}

		@include media-breakpoint-down(sm) {
			gap: 1.5rem 0;
			grid-template-columns: 1fr;

			.wrapper {
				height: 100%;
				aspect-ratio: 2 / 1;
			}
		}
	}
}

.post-tease {
	.wrapper {
		position: relative;
		aspect-ratio: 1 / 1;
		border-radius: 0.375rem;
		overflow: clip;
		display: flex;

		@include media-breakpoint-down(md) {
			aspect-ratio: auto;
		}

		.cover {
			background: #01418b;
			height: 100%;

			picture,
			img {
				height: 100%;
				width: 100%;
				object-fit: cover;
				opacity: 0.85;
			}
		}

		.content {
			position: absolute;
			height: 100%;
			width: 100%;
			top: 0;
			left: 0;
			display: flex;
			flex-direction: column;
			background: linear-gradient(
				to top,
				rgba(62,62,62, 1),
				rgba(15,41,71, 0) 60%
			);
			padding: 1.5rem;

			.title {
				@extend .typo-h;
				color: $white;
				text-decoration: none;
				font-size: 1.75rem;
				margin-top: auto;
				line-height: 1.25;

				@include media-breakpoint-down(xxl) {
					font-size: 1.375rem;
				}

				@include media-breakpoint-down(md) {
					font-size: 1.25rem;
				}
		

				// &:hover {
				// 	text-decoration: underline;
				// }
			}
			.category {
				@extend .typo-l;
				color: $white;
				margin-top: 1.5rem;
				text-decoration: none;

				// &:hover {
				// 	color: $azure;
				// }
			}
		}

		&:hover {
			.content {
				background: linear-gradient(
					to top,
					rgba($black, 0.9),
					transparent
				);
				.title {
					color: $azure;
				}
			}
		}
	}
}