/* SASS Variables */
$body: #f7f9fb;
$menu: #f2f2f5;
$text: #3e3e3e;
$border: $text;
$light: #f9f9f9;
$dark: #706f6f;
$azure: #0c9bdc;
$orange: #ea572e;
$green: #65b32e;
$violet: #951b81;
$sky: #49bed8;
$font: "DM Sans", sans-serif;
$font-icons: "Font Awesome 6 Pro";
$font-brands: "Font Awesome 6 Brands";

@function text-contrast($color) {
	@if (lightness($color) > 50) {
		@return $text;
	} @else {
		@return $white;
	}
}

/* CSS Variables */
:root {
	--font: "DM Sans", sans-serif;
	--font-icons: "Font Awesome 6 Pro";
	--font-brands: "Font Awesome 6 Brands";
	--plyr-color-main: #0c9bdc;
	--fa-primary-color: #0c9bdc;
	--fa-secondary-color: #002060;
	--azure: #0c9bdc;
	--orange: #ea572e;
	--green: #65b32e;
	--violet: #951b81;
	--sky: #49bed8;
}

/* Font-size reference for rem unit */
html {
	font-size: 16px;
	scroll-behavior: smooth;
}

/* reset outlines on focus and fields */
*:focus,
input:required {
	outline: 0 !important;
	box-shadow: none !important;
}

/* Body base styles */
body {
	position: relative;
	background: $body;
	color: $text;
	font-family: $font;
	font-weight: 400;
	line-height: 1.5;
	vertical-align: baseline;
	text-size-adjust: none;
	-webkit-text-size-adjust: none !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: clip;

	&.modal-open {
		padding-right: 0 !important;
	}
}

/* Picture tag fix */
picture {
	display: block;
}

/* reset last paragraph margin */
* > p:last-child {
	margin-bottom: 0;
}

/* General links */
a {
	color: inherit;
	transition: 0.3s linear;

	* {
		transition: 0.3s linear;
	}

	&:hover {
		color: $azure;
	}
}

/* BGs */
.bg-color {
	background-color: $azure;
}

.bg-white {
	background-color: $white;
}

.bg-towhite {
	background: linear-gradient(to bottom, $body, $white);
}

.bg-fromwhite {
	background: linear-gradient(to bottom, $white, $body);
}

.text-azure {
	color: #0c9bdc;
}

.text-orange {
	color: #ea572e;
}

.text-green {
	color: #65b32e;
}

.text-violet {
	color: #951b81;
}

.text-sky {
	color: #49bed8;
}

@media (prefers-reduced-motion: reduce) {
	* {
		transition: none;
		animation: none;
	}
}

.gy-fix {
	--bs-gutter-y: 1.5rem;
}
