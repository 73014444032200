.mobile-menu {
	.offcanvas-body {
		padding: 0;
		display: flex;
		flex-direction: column;
	}

	@include media-breakpoint-up(lg) {
		display: none;
	}

	.header {
		padding: 0.75rem 1rem;
		border-bottom: 1px solid rgba($border, 0.2);

		.header-wrapper {
			display: flex;
			align-items: center;

			.current {
				font-size: 1.125rem;
				font-weight: bold;
				text-transform: uppercase;
				color: $azure;
			}

			.action {
				border: 0;
				color: $white;
				background: $text;
				width: 2.5rem;
				height: 2.5rem;
				padding: 0;
				transition: 0.3s;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				border-radius: 0.375rem;
				flex-shrink: 0;
				margin: 0 auto 0 0;
				font-size: 1rem;

				i {
					transition: 0.3s linear;
				}

				&:hover {
					background: $azure;

					i {
						transform: scale(1.125);
					}
				}
				&.back {
					margin: 0 auto 0 0;
				}
			}
		}
	}

	.navigation {
		padding: 1rem;
		flex-grow: 1;
		overflow: auto;

		.mobile-nav {
			padding: 0;

			.nav-item {
				color: $text;
				position: relative;
				display: flex;
				border-bottom: 1px solid rgba($border, 0.2);

				.nav-link {
					font-size: 1rem;
					line-height: 1;
					color: $text;
					position: relative;
					padding: 1rem 0;
					width: 100%;
					display: flex;
					gap: 1rem;
					flex-grow: 1;
					white-space: normal;
					font-weight: bold;

					&:hover {
						color: $azure;
					}

					.icon {
						font-size: 1.5rem;

						&.svg {
							picture,
							img {
								height: 2rem;
								width: 2rem;
							}
						}
					}
					.content {
						white-space: wrap;

						.label {
							display: block;
						}
						.description {
							font-weight: normal;
							font-size: 0.875rem;
							line-height: 1.25;
							margin-top: 0.25rem;
						}
					}
				}

				// Toggles
				.menu-toggle {
					display: flex;
					margin-left: auto;
					font-size: 1rem;
					align-items: center;
					justify-content: center;
					width: 3.5rem;
					color: $text;
					cursor: pointer;
					background: $light;

					&:hover {
						color: $azure;
					}

					i {
						transition: 0.3s linear;
					}
				}

				&.active {
					.nav-link {
						color: $azure;
					}
				}
			}
		}
	}

	.social-links {
		border-top: 1px solid rgba($border, 0.2);
		margin-top: auto;
		padding: 0.5rem 1.5rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 1rem;
		background: $azure;

		a {
			color: $white;
			text-decoration: none;
			font-size: 1.75rem;
			line-height: 1;
			display: flex;
			align-items: center;
			justify-content: center;

			&:hover {
				color: $azure;
			}
		}
	}
}

@media (hover: none) {
	.mobile-menu {
		@include media-breakpoint-up(lg) {
			display: flex;
		}
	}
}
