.homepage-visual {
	display: flex;
	position: relative;
	background: $azure;
	padding: 4rem 0;
	background-blend-mode: hard-light;
	width: 100%;
	height: calc((100vw / 1920) * 800);
	overflow: clip;
	align-items: center;
	justify-content: center;
	overflow: clip;

	@include media-breakpoint-down(md) {
		height: auto;
	}

	@include media-breakpoint-up(xxl) {
		margin-bottom: 3rem;
	}

	.background {
		position: absolute;
		inset: 0;
		z-index: 0;  
		mix-blend-mode: hard-light;

		picture, img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.clip {
		position: absolute;
		bottom: -1px;
		left: 0;
		height: 3vw;
		width: 100%;

		path {
			fill: $body;
		}
	}

	.wrapper {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 1.5rem;
		align-items: center;
		position: relative;
		z-index: 1;

		.content {
			.title {
				@extend .typo-h1;
				color: $white;
			}

			.link {
				display: flex;
				margin-top: 2rem;
				gap: 1rem;
				flex-wrap: wrap;
			}
		}

		.animation {
			svg {
				transform: scale(1.2);
			}
		}

		@include media-breakpoint-down(md) {
			grid-template-columns: 1fr;

			.content {
				order: 2;
				text-align: center;

				.link {
					justify-content: center;
				}
			}

			.animation {
				svg {
					display: block;
					max-width: 20rem;
					margin: 0 auto;
				}

				@include media-breakpoint-down(md) {
					width: 50vw;
					margin: 0 auto;
				}
			}

	@include media-breakpoint-down(md) {
		height: auto;
	}
		}
	}
}
