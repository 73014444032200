.block-accordion {
	padding: 3rem 0;

	@include media-breakpoint-down(md) {
		padding: 2rem 0;
	}

	@include media-breakpoint-up(xxl) {
		padding: 4rem 0;
	}
	
	.accordion {
		background: $white;
		box-shadow: 0 0.375rem 0.375rem rgba($black, 0.15);
		box-shadow: 0 1.375rem 1.625rem #00000012;
		border-radius: 0.375rem;
		padding: 3rem;

		@include media-breakpoint-up(xxl) {
			max-width: 75%;
			margin: 0 auto;
		}

		@include media-breakpoint-down(md) {
			margin-bottom: 2rem;
		}

		.accordion-item {
			border: 0;
			margin-bottom: 3rem;
			padding: 1.5rem;
			box-shadow: 0 0.375rem 0.375rem rgba($black, 0.15);
			box-shadow: 0 1.375rem 1.625rem #00000012;
			border-radius: 0.375rem;

            @include media-breakpoint-down(md) {
                margin-bottom: 2rem;
            }

			&:last-child {
				margin-bottom: 0;
			}

			.wrapper {
				.trigger {

					.title {
						color: $text;
						display: flex;
						align-items: center;
						-webkit-appearance: none;

						h4 {
							@extend .typo-h3;
						}

						i {
                            color: $azure;
							display: flex;
							align-items: center;
							justify-content: center;
							font-size: 1.25rem;
							line-height: 1.25;
							margin-left: auto;
							transition: 0.15s ease-in-out;
						}

						&:hover {
							color: $azure;
						}

						&:not(.collapsed) {
							color: $azure;
							i {
								transform: rotate(45deg);
							}
						}

						@include media-breakpoint-down(md) {
							padding: 1rem;
						}
					}
				}

				.content {
					padding: 1.5rem 0 0;

					.text {
						@extend .typo-r;
					}
				}
			}
		}
	}
}
