.block-video {
	padding: 3rem 0;

	@include media-breakpoint-down(md) {
		padding: 2rem 0;
	}

	@include media-breakpoint-up(xxl) {
		padding: 4rem 0;
	}

    .video {
        video {
            background: $light;
            position: relative;

            &[poster] {
                height: 100%;
                width: 100%;
            }
        }
    }
}