.block-team {
	overflow-x: clip;
	padding: 3rem 0;

	@include media-breakpoint-down(md) {
		padding: 2rem 0;
	}

	@include media-breakpoint-up(xxl) {
		padding: 4rem 0;
	}

	.team {
		position: relative;
		display: grid;
		grid-template-columns: repeat(var(--elements), 1fr);
		gap: 1.5rem;

		@include media-breakpoint-down(lg) {
			grid-template-columns: repeat(2, 1fr);
		}

		@include media-breakpoint-down(sm) {
			grid-template-columns: 1fr;
		}

		.member {
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;

			.portrait {
				margin-bottom: 1rem;

				.aesse-image {
					.frame {
						picture,
						img {
							z-index: 5;
						}

						&::before {
							z-index: 0;
							animation: none;
						}
					}
				}
			}

			.name {
				font-size: 1.25rem;
				color: $azure;
				font-weight: bold;
				margin-bottom: 0.25;
			}
			.role {
				margin-bottom: 1rem;
			}
			.contacts {
				display: flex;
				gap: 1rem;
				align-items: center;
				justify-content: center;

				a {
					font-size: 2rem;
					color: $azure;

					&:hover {
						color: $text;
					}
				}
			}

			&:nth-child(even) {
				.aesse-image {
					.frame {
						&::before {
							animation-name: rotateAnimationCcw;
						}
					}
					&::after {
						right: -10%;
						bottom: auto;
						left: auto;
					}
				}
			}
		}
	}
}
