.cta {
	padding: 5rem 0;
	background-color: $azure;
	text-align: center;
	background: url("../images/waves.svg") no-repeat $azure top center / cover;
	position: relative;

	@include media-breakpoint-down(md) {
		padding: 3rem 0;
	}

	@include media-breakpoint-up(xxl) {
		padding: 6rem 0;
	}


	.clip {
		height: 3vw;
		width: 100%;
		position: absolute;
		top: -1px;
		left: 0;

		path {
			fill: $body;
		}
	}

	.wrapper {
		max-width: 70%;
		margin: 0 auto;

		@include media-breakpoint-down(lg) {
			max-width: 100%;
		}

		.label {
			@extend .typo-l;
			color: $white;
			margin-bottom: .25rem;
		}

		.title {
			@extend .typo-h2;
			color: $white;
			margin: 0 0 1.25rem;
		}

		.text {
			@extend .typo-r;
			color: $white;
			margin: 0 0 1.25rem;
		}

		.link {
			display: flex;
			gap: 1rem;
			justify-content: center;
			margin-top: 1.25rem;
			flex-wrap: wrap;
		}
	}
}

.related-posts + .cta,
.bg-white + .cta,
.bg-towhite + .cta {
	.clip {
		path {
			fill: $white;
		}
	}
}
