.swiper-pagination {
    position: relative;
    bottom: 0 !important;
    text-align: center;
    transition: .3s opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10;
    margin: 1rem auto;

    .swiper-pagination-bullet {
        width: 3rem;
        height: .25rem;
        display: inline-block;
        background: rgba($azure, .5);
        opacity: 1;
        border: 0;
        // margin: 0;
        border-radius: 0;

        &.swiper-pagination-bullet-active {
            background: $azure;
        }
    }

}

.swiper-button-disabled {
    opacity: .25 !important;
}

.swiper-navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;

    .swiper-next,
    .swiper-prev {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.25rem;
        color: $azure;
        border: 2px solid $azure;
        border-radius: 50%;
        height: 2rem;
        width: 2rem;
        cursor: pointer;
    }
}