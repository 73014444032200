.block-bullets {
	padding: 3rem 0;

	@include media-breakpoint-down(md) {
		padding: 2rem 0;
	}
	
	@include media-breakpoint-up(xxl) {
		padding: 4rem 0;
	}
	
	.bullets {
		@extend .typo-r;

		&.columns {
			columns: 2;
			column-gap: 3rem;

			@include media-breakpoint-down(md) {
				columns: 1;
			}
		}

		li {
			break-inside: avoid-column;
		}
	}
}
