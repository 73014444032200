.block-form {
    padding: 3rem 0;
    
    @include media-breakpoint-down(md) {
        padding: 2rem 0;
    }

	@include media-breakpoint-up(xxl) {
		padding: 4rem 0;
	}

    .intro {
		&.with-bg {
			position: relative;
			padding: 3rem;
			color: $white;
			max-width: 100%;
			z-index: 1;

			.label {
				color: $white !important;
			}

			@include media-breakpoint-down(md) {
				padding: 2rem;
			}

			@include media-breakpoint-up(xxl) {
				padding: 4rem;
			}

			&::before {
				display: block;
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				height: calc(100% + 2rem + 10rem);
				width: 100%;
				z-index: -1;
				background: var(--introbg) no-repeat center / cover;
			}

			&::after {
				display: block;
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				height: calc(100% + 2rem + 10rem);
				width: 100%;
				z-index: -1;
				background: linear-gradient(
					to bottom,
					rgba(0,0,0, .75),
					rgba(0,0,0,0) 80%
				);
			}
		}
	}

	.intro.with-bg + .form {
		margin: 0 3rem;
		position: relative;
		z-index: 1;
        background: $white;
        padding: 2rem;
		border-radius: 0.375rem;

		@include media-breakpoint-down(md) {
			margin: 0 2rem;
		}

		@include media-breakpoint-up(xxl) {
			margin: 0 4rem;
		}
	}

}

.grecaptcha-badge {
    display: none !important;
}