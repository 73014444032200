.block-review {
	padding: 3rem 0;

	@include media-breakpoint-down(md) {
		padding: 2rem 0;
	}

	@include media-breakpoint-up(xxl) {
		padding: 4rem 0;
	}

	.review-slider {
		background-color: $azure;
        background: url("../images/waves.svg") no-repeat $azure top center /
            cover;
		border-radius: 0.375rem;

		@include media-breakpoint-up(xxl) {
			max-width: 80%;
		}
        
		.swiper-slide {
			height: auto !important;
			display: flex;

			.wrapper {
				padding: 3rem 3rem 0;
				display: flex;
				grid-template-columns: 1fr;
                gap: 1.5rem;

				@include media-breakpoint-down(md) {
                    flex-direction: column;
					padding: 2rem 2rem 0;
                    gap: 1rem;
				}

				&.with-media {
					grid-template-columns: 80% 20%;
				}

				.content {
					color: $white;
					display: block;
					position: relative;

					.text {
						@extend .typo-h3;
						font-size: 1.375rem;
						line-height: 1.25;
					}

					.name {
						font-size: 1rem;
						margin-top: 1.5rem;
                        font-weight: bold;
					}

					.role {
						font-size: 1rem;
					}
				}

				.media {
                    display: flex;
                    gap: 1rem;
                    flex-direction: column;
                    max-width: 25%;
                    flex-shrink: 0;

                    @include media-breakpoint-down(md) {
                        flex-direction: row;
                        max-width: 100%;
                        align-items: center;
                        gap: 1.5rem;
                    }

					.image {
                        border-radius: 50%;
                        overflow: clip;

                        @include media-breakpoint-down(md) {
                            max-width: 40%;
                        }
					}

					.logo {
					}
				}
			}
		}

		.review-pagination {
			.swiper-pagination-bullet {
				background: rgba($white, 0.5);

				&.swiper-pagination-bullet-active {
					background: $white;
				}
			}
		}
	}
}
