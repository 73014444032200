.homepage-header {
	padding: 3rem 0;
	// text-align: center;
	position: relative;
	background: $body;

	@include media-breakpoint-down(md) {
		padding: 2rem 0;
	}

	@include media-breakpoint-up(xxl) {
		padding: 4rem 0;
	}


	.wrapper {
		max-width: 80%;
		margin: 0 auto;

		.label {
			@extend .typo-l;
			color: $azure;
			margin-bottom: 0.25rem;
		}

		.title {
			@extend .typo-h2;
			color: $text;
			margin: 0 0 1rem;
		}

		.description {
			@extend .typo-r;
			color: $text;
			margin: 0 auto 1rem;
			max-width: 60%;

			@include media-breakpoint-down(lg) {
				max-width: 100%;
			}

			&.columns {
				column-gap: 3rem;
				columns: 2;
				text-align: left;
				max-width: 100%;

				@include media-breakpoint-down(lg) {
					columns: 1;
				}
			}
		}

		.link {
			display: flex;
			gap: 1rem;
			justify-content: center;
			margin-top: 1.5rem;
			flex-wrap: wrap;
		}
	}
}

.homepage-news {
	@extend .blog-grid;

	.blog-grid {
		background: $white !important;
	}

	.post-grid {
		display: grid;
		grid-template-columns: 2fr 1fr 1fr;
		grid-template-rows: auto;
		gap: 1.5rem;

		@include media-breakpoint-down(lg) {
			grid-template-columns: 1fr 1fr;
		}

		@include media-breakpoint-down(sm) {
			gap: 1.5rem 0;
			grid-template-columns: 1fr;

			.wrapper {
				height: 100%;
				aspect-ratio: 2 / 1;
			}
		}

		.post-tease:first-child {
			grid-row: span 2;

			@include media-breakpoint-down(lg) {
				grid-column: span 2;
				grid-row: auto;
			}

			@include media-breakpoint-down(sm) {
				grid-row: auto;
				grid-column: auto;
			}

			.wrapper {
				height: 100%;
				aspect-ratio: auto;

				@include media-breakpoint-up(xxl) {  
					.content {
						.title {
							max-width: 75%;
						}
					}
				}

				@include media-breakpoint-down(lg) {  
					aspect-ratio: 2 / 1;
				}

				@include media-breakpoint-down(sm) {  
					aspect-ratio: 2 / 1;
				}
		
			}
		}
	}
}
