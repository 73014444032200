.main-menu {
	display: flex;
	padding: 0;
	align-items: center;
	gap: 1rem;
	flex-grow: 1;

	@include media-breakpoint-up(xxl) {
		gap: 2.5rem;
	}

	@include media-breakpoint-up(xl) {
		gap: 1.25rem;
	}

	.nav-item {
		display: flex;
		position: relative;
		color: $text;
		transition: 0.3s linear;
		text-align: center;
		height: 100%;

		.nav-link {
			display: flex;
			position: relative;
			font-size: 0.875rem;
			font-weight: bold;
			justify-content: center;
			align-items: center;
			line-height: 1;
			padding: 0.5rem 0rem;
			align-self: center;
			cursor: pointer;

			@include media-breakpoint-up(xl) {
				font-size: 0.9375rem;
			}

			i {
				font-size: 0.75rem;
				margin-left: 0.5em;

				@include media-breakpoint-up(xl) {
					font-size: 0.875rem;
				}

				@include media-breakpoint-down(xl) {
					display: none;
				}
			}
		}

		&::after {
			display: block;
			position: absolute;
			bottom: 0;
			left: 0;
			content: "";
			background: $azure;
			height: 0.25rem;
			width: 100%;
			transform: scaleX(0);
			transition: 0.3s linear;
		}

		&.visible,
		&.active {
			color: $azure;

			&::after {
				transform: scaleX(1);
			}
		}

		&.contact {
			color: $azure;

			@include media-breakpoint-up(xxl) {
				margin-left: auto;
			}

			.nav-link {
				color: inherit;
			}

			&.visible,
			&.active {
				.nav-link {
					color: $text;
				}

				&::after {
					transform: scaleX(0);
				}
			}
		}

		&.support {
			.nav-link {
				font-size: 0.75rem;
				border-radius: 1.75rem;
				background: $azure;
				color: $white;
				padding: 0.9375rem 1.5rem;
				text-transform: uppercase;
				transition: 0.3s linear;
				letter-spacing: 0.15em;

				@include media-breakpoint-down(xl) {
					padding: 0.9375rem 1.125rem;
				}
			}

			&.visible,
			&.active {
				.nav-link {
					padding: 1.25rem 1.5rem;
					background: $text;
					color: $white;
				}

				&::after {
					transform: scaleX(0);
				}
			}
		}

		// Dropdown
		&.dropdown {
			position: relative;

			.dropdown-menu {
				// display: flex; // debug
				position: absolute;
				top: calc(100% + 0.5rem);
				left: -5rem;
				z-index: 1000;
				float: left;
				padding: 0.25rem;
				margin: 0;
				text-align: left;
				list-style: none;
				background-color: $menu;
				border: 0;
				min-width: 18rem;
				box-shadow: 0 0.375rem 0.375rem rgba($black, 0.15);
				border-radius: 0.375rem;

				@include media-breakpoint-up(xxl) {
					padding: 0.5rem;
				}

				// standard
				.standard {
					padding: 0.125rem;
					max-width: 18rem;
					flex-grow: 1;

					@include media-breakpoint-up(xxl) {
						max-width: 20rem;
					}

					.dropdown-item {
						display: flex;
						white-space: normal;
						font-size: 0.875rem;
						padding: 0.5rem;
						line-height: 1;
						color: $black;
						font-weight: normal;
						text-decoration: none;
						gap: 1rem;
						border-radius: 0.375rem;
						flex-grow: 1;

						@include media-breakpoint-up(xxl) {
							padding: 1rem;
							font-size: 1rem;
						}

						.icon {
							font-size: 1.5rem;

							&.svg {
								picture,
								img {
									height: 2rem;
									width: 2rem;
								}
							}
						}
						.content {
							white-space: wrap;

							.label {
								display: block;
								font-weight: bold;
							}
							.description {
								font-size: 0.75rem;
								line-height: 1.25;
								margin-top: 0.25rem;

								@include media-breakpoint-up(xxl) {
									font-size: 0.875rem;
									line-height: 1.4;
								}
							}
						}

						&:last-child {
							margin-bottom: 0;
						}

						&:hover,
						&.active {
							background: $white;
							color: $text;

							i {
								color: $azure;
							}
						}
					}
				}
			}

			&.visible {
				.dropdown-menu {
					display: flex;
				}

				&::before {
					content: "";
					background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAxMiI+PHBhdGggZmlsbD0iI0YwRjNGOCIgZD0iTTAgMTJMMTIgMGwxMiAxMnoiLz48L3N2Zz4=")
						no-repeat center / contain;
					height: 0.75rem;
					width: 1.5rem;
					position: absolute;
					left: 50%;
					margin-left: -0.75rem;
					bottom: -0.75rem;
				}
			}

			&.active {
				color: $azure;
			}

			&:not(.mega):last-child {
				.dropdown-menu {
					right: 0;
					left: auto;
				}
			}
		}
	}
}

@media (hover: none) {
	.main-menu {
		display: none !important;
	}
}