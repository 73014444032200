.block-cta {
	position: relative;
	padding: 3rem 0;
	text-align: center;

	.wrapper {
		.content {
			margin: 0 auto;
			max-width: 70%;

			.label {
				margin-bottom: 0.25rem;
				color: $azure;
				@extend .typo-l;
			}

			.title {
				margin: 0 0 1.25rem;
				@extend .typo-h3;
			}

			.text {
				margin: 0 0 1.25rem;
				@extend .typo-r;
			}

			.link {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				gap: 1rem;
				margin-top: 1.5rem;
			}

			@include media-breakpoint-down(lg) {
				max-width: 100%;
			}
		}
	}

	&.boxed {
		// padding-top: 0;

		.wrapper {
			border-radius: 0.375rem;
			background: url("../images/waves.svg") no-repeat $azure top center /
				cover;
			background-color: $azure;
			padding: 5rem 1rem;

			.label {
				color: $white;
			}

			.title {
				color: $white;
			}

			.text {
				color: $white;
			}

			@include media-breakpoint-down(md) {
				padding: 3rem 1rem;
			}
		}
	}

	@include media-breakpoint-down(md) {
		padding: 2rem 0;
	}

	@include media-breakpoint-up(xxl) {
		padding: 4rem 0;
	}
}

.block-cta-alt {
	background: $light;

	&.with-image {
		padding: 3rem;
		text-align: left;

		.wrapper {
			display: grid;
			grid-template-columns: 2fr 3fr;
			gap: 3rem;
			border-radius: 0.375rem;
			background: $light;
			padding: 3rem;

			.content {
				position: relative;
				align-self: center;
				margin: 0;
				max-width: 100%;

				.title {
					margin: 0 0 1.25rem;
					color: $azure;
					@extend .typo-h2;
				}
				.link {
					justify-content: start;
				}
			}

			.media {
				position: relative;
				margin-top: -5rem;

				@include media-breakpoint-down(md) {
					margin-top: -4rem;
				}

				@include media-breakpoint-up(xxl) {
					margin-top: -6rem;
				}
			}

			@include media-breakpoint-down(lg) {
				grid-template-columns: 1fr;
			}

			@include media-breakpoint-down(md) {
				padding: 2rem;
			}

			@include media-breakpoint-up(xxl) {
				padding: 4rem;
			}
		}

		&.boxed-alt {
			background: $white;
			.wrapper {
				margin: 0 auto;
				@include media-breakpoint-up(xxl) {
					max-width: 80%;
				}
			}
		}

		&.full {
			overflow-x: clip;
			position: relative;
			padding-bottom: 0;

			&::before {
				display: block;
				position: absolute;
				top: 0;
				left: 50%;
				transform: translateX(-50%);
				z-index: 0;
				background: $white;
				width: 100vw;
				height: 3rem;
				content: "";

				@include media-breakpoint-down(md) {
					height: 2rem;
				}

				@include media-breakpoint-up(xxl) {
					height: 4rem;
				}
			}

			.wrapper {
				position: relative;

				&::before {
					display: block;
					position: absolute;
					top: 0;
					left: 50%;
					transform: translateX(-50%);
					z-index: 0;
					background: $light;
					width: 100vw;
					height: 100%;
					content: "";
				}
			}
		}

		@include media-breakpoint-down(md) {
			padding: 2rem 0;
		}

		@include media-breakpoint-up(xxl) {
			padding: 4rem 0;
		}
	}
	@extend .block-cta;
}
