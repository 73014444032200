.block-numbers {
	padding: 3rem 0;

	@include media-breakpoint-down(md) {
		padding: 2rem 0;
	}

	@include media-breakpoint-up(xxl) {
		padding: 4rem 0;
	}

	.intro {
		.icon {
			display: flex;
			position: relative;
			width: 7.5rem;
			height: 7.5rem;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			box-shadow: 0 0 0.75rem rgba($black, 0.15);
			margin: 0 auto 3rem;
			background: $light;

            &::after {
                content: "";
                position: absolute;
                border-radius: 50%;
                display: block;
                height: 20%;
                aspect-ratio: 1 / 1;
                left: -20%;
                bottom: -5%;
                background: $light;
				box-shadow: 0 0 0.75rem rgba($black, 0.15);
            }

			img {
				width: 6rem;
				height: 6rem;
				object-fit: contain;
				object-position: center;
                // filter: brightness(0) invert(1);
			}
		}
	}

	.number-card {
		display: flex;
		position: relative;
		background: $white;
		box-shadow: 0 0.375rem 0.375rem rgba($black, 0.15);
		box-shadow: 0 1.375rem 1.625rem #00000012;
		text-align: center;
		flex-direction: column;
		align-items: center;
		border-radius: 0.375rem;
		padding: 1.5rem 2rem;
		flex-grow: 1;
		margin-bottom: 2rem;

		.content {

            .number {
                @extend .typo-h1;
				margin-bottom: .5rem;

            }
            .title {
                margin-bottom: 1rem;
                @extend .typo-h3;
                text-transform: uppercase;
            }

            .description {
                @extend .typo-l;
                color: $azure;
            }
		}
	}

}
